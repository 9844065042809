import React, { Component } from "react"

class Github extends Component {
  static defaultProps = {
      viewBox: '0 0 200 200',
      width: '32px',
      height: '32px',
      fill: '#000',
    }
  render() {
    let {
        width,
        height,
        style,
        viewBox,
        className,
        fill} = this.props;
    return (<svg
    width={width}
    style={style}
    height={height}
    viewBox={viewBox}
    xmlns="http://www.w3.org/2000/svg"
    className={`svg-icon ${className || ""}`}
    xmlnsXlink="http://www.w3.org/1999/xlink"
    >
        <path fill={fill}
            d="M184.52,53.39A97.22,97.22,0,0,0,149,17.86,95.48,95.48,0,0,0,100,4.77,95.55,95.55,0,0,0,51,17.86,97.17,97.17,0,0,0,15.47,53.39a95.54,95.54,0,0,0-13.09,49A94.89,94.89,0,0,0,21,159.77,95.6,95.6,0,0,0,69.11,195c2.29.43,4,.13,5.09-.89a5,5,0,0,0,1.65-3.81q0-.37-.07-6.86t-.06-11.31l-2.92.5a36.71,36.71,0,0,1-7.06.45,54,54,0,0,1-8.83-.89,19.81,19.81,0,0,1-8.52-3.81,16.14,16.14,0,0,1-5.59-7.82l-1.27-2.92a31.84,31.84,0,0,0-4-6.48A15.3,15.3,0,0,0,32,146.37l-.89-.64a8.48,8.48,0,0,1-1.65-1.53,7,7,0,0,1-1.15-1.78c-.25-.59,0-1.08.64-1.46a8.24,8.24,0,0,1,3.68-.57l2.54.38a18.65,18.65,0,0,1,6.3,3,20.56,20.56,0,0,1,6.16,6.61,22.45,22.45,0,0,0,7,7.94A14.94,14.94,0,0,0,63,161.11a36.18,36.18,0,0,0,7.24-.64A24.82,24.82,0,0,0,76,158.56q1.16-8.51,6.23-13.09a87,87,0,0,1-13-2.29,51.59,51.59,0,0,1-11.94-5A34,34,0,0,1,47,129.71a41,41,0,0,1-6.67-13.35,63.35,63.35,0,0,1-2.6-19.06,37.06,37.06,0,0,1,10-26.19q-4.71-11.56.89-25.92c2.45-.77,6.1-.19,10.93,1.71a75.6,75.6,0,0,1,10.61,4.9c2.25,1.35,4,2.5,5.4,3.43a91.63,91.63,0,0,1,48.81,0l4.83-3a68.68,68.68,0,0,1,11.7-5.6q6.73-2.53,10.29-1.39,5.73,14.36,1,25.92a37.12,37.12,0,0,1,10,26.19,64.33,64.33,0,0,1-2.6,19.13,39.28,39.28,0,0,1-6.74,13.34,35.59,35.59,0,0,1-10.29,8.46,52,52,0,0,1-12,5,85.71,85.71,0,0,1-13,2.29q6.6,5.73,6.61,18.05v26.82a5.07,5.07,0,0,0,1.59,3.81q1.59,1.51,5,.89A95.66,95.66,0,0,0,179,159.77a94.91,94.91,0,0,0,18.62-57.39A95.55,95.55,0,0,0,184.52,53.39Z"
        />
  </svg>);
  }
}

export default Github
